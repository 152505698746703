import '../styles/com.scss';

import React from 'react';

import SEO from '../components/seo';

const caMale = 'https://cdn.getmybubble.com/images/corporate-sites/arizona-male.png';
const caFemale = 'https://cdn.getmybubble.com/images/corporate-sites/arizona-female.png';

import Layout from '../components/layout';
import StateInsurance from '../components/state-insurance';

function CaliforniaInsurance() {

  return(
    <Layout>
      <SEO
        keywords='life insurance arizona , best life insurance in arizona , life, insurance quotes arizona , best term life insurance in arizona , term life insurance arizona , life insurance quotes in arizona '
        title='Best Life Insurance Plans &amp; Policies In Arizona | Get Insurance Quotes Online'
        description="Get a life insurance quote online in Arizona in minutes. Bubble offers you the best-suited life insurance plans &amp; policies in Arizona to ensure your family's financial future is safe."/>

      <div className="top-section">

      </div>

      <div className="row">
        <div className="col-lg-6 col-12">
          <img src={caFemale} alt="Female Coverages" />
        </div>

        <div className="col-lg-6 col-12">
          <img src={caMale} alt="Male Coverages" />
        </div>
      </div>

      <div className="row orange-bg" >
        <div className="col-lg-2"></div>

        <div className="col-lg-8 d-md-12">
          <div className="flex-column d-flex">
            <h1 className='text-center'>
            Life Insurance, Arizona
            </h1>

            <p>
            Life insurance is a financial buffer you help your loved ones with to protect them financially when you're no longer there. Life insurance in Arizona provides a tax-free lump sum of money, called the death benefit, to a designated beneficiary after your death. You get to pick the life insurance policy that's right for you by checking the life insurance quote. When you buy a life insurance policy when you're young and healthy, your life insurance premium is most likely to be on the lower side. You can also buy life insurance for kids or even your parents. If you’re in Arizona, get your life insurance quote online with Bubble and protect everyone you love in minutes.
            </p>
          </div>
        </div>

      </div>

      <div className="container">
        <div className="row info-para">
          <div className="col-lg-2"></div>

          <div className="col-lg-8 d-md-12">
            <div className="flex-column d-flex">
              <h2 className='text-center'>
              Your Life Insurance premium is cheaper when you’re young
              </h2>

              <p>
              Here’s a break up of what life insurance in Arizona would be like for you. A life insurance policy in Arizona for a young person in the age group of 20 to 30 works out a lot cheaper than for someone in the age group of 40 to 50. When you’re younger your life insurance premium is lower and with Bubble, it can be locked for decades.
              </p>
            </div>
          </div>

        </div>
      </div>

      <StateInsurance/>
    </Layout>
  );
}

export default CaliforniaInsurance;
